import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Footer, Header, Slider, Loader } from "../layouts/general";

import { Services } from "../layouts/general/services";

class Home01 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          names: "Home",
        },
      ],
    };
  }
  render() {
    return (
      <div className="bg-body3">
        <div className="boxed" style={{ position: "relative" }}>
          <Loader />
          {/* <TopBar /> */}
          {this.state.headers.map((data) => (
            <Header data={data} key={data.id} />
          ))}
          <Slider />

          <Services />

          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(Home01);
