import React, { Component } from "react";
import { Link } from "react-router-dom";
class EventBoxs2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataEvents: [
        {
          id: 4,
          classicon: "icon-rounded magic",
          title: "Cloud Transformation",
          description:
            "-Multi Cloud\n-Cloud Services\n-Cloud Consulting & Implementation",
        },
        {
          id: 5,
          classicon: "icon-rounded artboard",
          title: "Security & Networking",
          description:
            "-Enterprise Networking\n-DataCenter Networking\n-Software Defined Networking\n-Cloud Based Networking\n-End to End Security",
        },
        {
          id: 6,
          classicon: "icon-rounded global",
          title: "Datacenter Platform",
          description:
            "-Computing\n-Storage\n-Backup\n-Recovery\n-Virtualization",
        },
      ],
    };
  }

  render() {
    return (
      <div className="row">
        {this.state.dataEvents.map((data) => (
          <div className="col-md-4" key={data.id}>
            <div className="iconbox-item">
              <div className="iconbox style1">
                <div className="box-header">
                  <div className={data.classicon}></div>
                  {/* <!-- /.icon-rounded --> */}
                  <div className="box-title">
                    <Link to="#" title="">
                      {data.title}
                    </Link>
                  </div>
                  {/* <!-- /.box-title --> */}
                </div>
                {/* <!-- /.box-header --> */}
                <div style={{ whiteSpace: "pre-wrap" }} className="box-content">
                  {data.description}
                </div>
                {/* <!-- /.box-content --> */}
              </div>
              {/* <!-- /.iconbox --> */}
            </div>
            {/* <!-- /.iconbox-item --> */}
          </div>
        ))}
      </div>
    );
  }
}

export default EventBoxs2;
