import React, { Component } from "react";
import { Link } from "react-router-dom";
class UsefulLink extends Component {
  render() {
    return (
      <div className="widget widget-services">
        <ul className="one-half first">
          <li>
            <Link
              to="/"
              onClick={() => {
                window.location.href = "/";
              }}
              title=""
            >
              CSR
            </Link>
          </li>
          <li>
            <Link
              to="/"
              onClick={() => {
                window.location.href = "/";
              }}
              title=""
            >
              Professional Services
            </Link>
          </li>
          <li>
            <Link
              to="/"
              onClick={() => {
                window.location.href = "/";
              }}
              title=""
            >
              Shop
            </Link>
          </li>
          <li>
            <Link
              to="/"
              onClick={() => {
                window.location.href = "/";
              }}
              title=""
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="/"
              onClick={() => {
                window.location.href = "/";
              }}
              title=""
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
      /* <!-- /.widget-services --> */
    );
  }
}

export default UsefulLink;
