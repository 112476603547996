import React, { Component } from "react";
import { Link } from "react-router-dom";
class ServicesBoxs1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataEvents: [
        {
          id: 1,
          classicon: "icon-rounded clipboard",
          title: "IT Services",
          description:
            "-Consulting Services\n-Financial Services\n-Managed Services\n-Professional Services\n-Residency\n-Training",
        },
        {
          id: 2,
          classicon: "icon-rounded line-chart",
          title: "Mobile Computing",
          description:
            "-Notebook\n-Mobile Workstation\n-Tablets\n-Mobile Thin Clients\n-Smartphones",
        },
        {
          id: 3,
          classicon: "icon-rounded clock",
          title: "Modern Workplace",
          description:
            "-Communication\n-Modern Meeting Room\n-Mobile Working\n-Workplace Security",
        },
      ],
    };
  }

  render() {
    return (
      <div className="row">
        {this.state.dataEvents.map((data) => (
          <div className="col-md-4" key={data.id}>
            <div className="iconbox-item">
              <div className="iconbox style1">
                <div className="box-header">
                  <div className={data.classicon}></div>
                  {/* <!-- /.icon-rounded --> */}
                  <div className="box-title">
                    <Link to="#" title="">
                      {data.title}
                    </Link>
                  </div>
                  {/* <!-- /.box-title --> */}
                </div>
                {/* <!-- /.box-header --> */}
                <div style={{ whiteSpace: "pre-wrap" }} className="box-content">
                  {data.description}
                </div>
                {/* <!-- /.box-content --> */}
              </div>
              {/* <!-- /.iconbox --> */}
            </div>
            {/* <!-- /.iconbox-item --> */}
          </div>
        ))}
      </div>
    );
  }
}

export default ServicesBoxs1;
