import React, { Component } from "react";
import { Link } from "react-router-dom";

class TopFooter extends Component {
  render() {
    return (
      <div className="widget-infomation">
        <ul className="infomation-footer">
          <li>
            <i className="fa fa-envelope" aria-hidden="true"></i>
            <Link to="#" title="">
              info@nexusteknoloji.com.tr
            </Link>
          </li>
          <li>
            <i className="fa fa-phone" aria-hidden="true"></i>
            <Link to="#" title="">
              +90(216) 999 87 57
            </Link>
          </li>
          <li style={{ marginTop: "10px" }}>
            <i className="fa fa-map-marker" aria-hidden="true"></i>
            <Link to="#" title="">
              Finanskent Mah. Finans Cad. Sarphan Finans Merkezi C Blok No:5/C
              D:55 Pk:34760 Ümraniye / İSTANBUL
            </Link>
          </li>
        </ul>
        {/* <!-- /.infomation-footer --> */}
      </div>
      /* <!-- /.widget-infomation --> */
    );
  }
}

export default TopFooter;
