import React, { Component } from "react";
import { Link } from "react-router-dom";
class BottomBar extends Component {
  render() {
    return (
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="copyright">
                <p>Copyright {new Date().getFullYear()} Nexus</p>
              </div>

              {/* <!-- /.menu-footer --> */}
            </div>
          </div>
        </div>
      </div>
      /* <!-- /.footer-bottom --> */
    );
  }
}

export default BottomBar;
