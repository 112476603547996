const menus = [
  {
    id: 1,
    name: "What we do?",
    linkmenu: "/",
    namesub: [
      {
        id: 1,
        sub: "IT Services",
        links: "/",
      },
      {
        id: 2,
        sub: "Mobile Computing",
        links: "/",
        submenu: undefined,
      },
      {
        id: 3,
        sub: "Modern Workplace",
        links: "/",
        submenu: undefined,
      },
      {
        id: 4,
        sub: "Cloud Transformation",
        links: "/",
        submenu: undefined,
      },
      {
        id: 5,
        sub: "Security & Networking",
        links: "/",
        submenu: undefined,
      },
      {
        id: 6,
        sub: "Datacenter Platform",
        links: "/",
        submenu: undefined,
      },
    ],
  },

  {
    id: 2,
    name: "IT Trends",
    linkmenu: "/",
    namesub: [
      {
        id: 1,
        sub: "5G",
        links: "/",
        submenu: undefined,
      },
      {
        id: 2,
        sub: "Artificial Intelligence(AI) & Machine Learning",
        links: "/",
        submenu: undefined,
      },
      {
        id: 3,
        sub: "Blockchain",
        links: "/",
        submenu: undefined,
      },
      {
        id: 4,
        sub: "Cyber Security",
        links: "/",
        submenu: undefined,
      },
      {
        id: 5,
        sub: "Everything as a Service (XaaS)",
        links: "/",
        submenu: undefined,
      },
      {
        id: 6,
        sub: "Internet of Things(IOT)",
        links: "/",
        submenu: undefined,
      },
      {
        id: 7,
        sub: "Robotic Process Automization(RPA)",
        links: "/",
        submenu: undefined,
      },
      {
        id: 8,
        sub: "Virtual Reality & Augmented Reality (VR,AR)",
        links: "/",
        submenu: undefined,
      },
      {
        id: 9,
        sub: "Quantum Computing",
        links: "/",
        submenu: undefined,
      },
    ],
  },
  {
    id: 3,
    name: "Content & Resources",
    linkmenu: "/",
  },
  {
    id: 4,
    name: "Nexus Technology",
    linkmenu: "/nexus",
    namesub: [
      {
        id: 1,
        sub: "About Nexus",
        links: "/",
        submenu: undefined,
      },
      {
        id: 2,
        sub: "Future Talents",
        links: "/",
        submenu: undefined,
      },
      {
        id: 3,
        sub: "CSR",
        links: "/",
        submenu: undefined,
      },
      {
        id: 4,
        sub: "Connect With Us",
        links: "/",
        submenu: undefined,
      },
      {
        id: 5,
        sub: "Business Partners",
        links: "/",
        submenu: undefined,
      },
    ],
  },
];

export default menus;
