import React, { Component } from 'react';

class TextMainFooter extends Component {
    render() {
        return (
            <div className="widget widget-text">
                <p>Lorem ipsum dolor sit consectetur adipiscing elit sed
                    do eiusmod tempor incididunt ut labore et dolore 
                        magna veniam aliqua.Ut enim ad minim veniam
                </p>
                <p>
                    Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in
                    voluptate 
                </p>	
            </div>
            /* <!-- /.widget-text --> */
        );
    }
}

export default TextMainFooter;